<template>
    <div>
        <div class="nav-menu" v-if="showMenu">
            <div :class="{
                'menu-item':true,
                'sel-color':selectMenu == item.key_name,
                'nsel-color':selectMenu!=item.key_name
            }" v-for="item in navList" :key="item.key_name" @click="clickMenu(item)">
                <div>{{item.key_name}}</div>
            </div>
        </div>
    </div>
</template>

<script>
// import navList from '@/utils/navMenu'
import eventBus from '@/utils/eventBus'
import { getClassList } from "@/api/games"
    export default {
        data() {
            return {
                navList:[],
                selectMenu:'Hot',
                showMenu:false
            }
        },
        mounted() {
            eventBus.$on('showmenu',i=>{                
                this.showMenu = i;
            })
            this.getList();
        },
        methods: {
            async getList(){
                let list = await getClassList();
                this.navList = list;
            },
            clickMenu(item){
                this.selectMenu = item.key_name;
                if(this.$route.path=='/'){
                    eventBus.$emit('selectMenu',item.key_name);
                }else{
                    this.$router.push({
                        name: 'index',
                        params:{
                            menuKey:item.key_name
                        }
                    })
                }
            }
        },
    }
</script>

<style lang="less" scoped>
@media (min-width: 768px) {
  .nav-menu{
    width: 1200px;
  }
}
@media (max-width: 768px) {
  .nav-menu{
    width: 100%;
  }
}
.nav-menu{
    &::-webkit-scrollbar{
        display: none;
    }
    height: 315px;
    background: #FFFFFF;
    border-radius: 1px;
    overflow: auto;
    position: fixed;
    z-index: 999;
    top: 50px;
    // left: 0;
    .sel-color{
        color: #F39792;
    }
    .nsel-color{
        color: #373952;
    }
    .menu-item{
        margin: 0 auto;
        width: 315px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 16px;
        line-height: 39px;
        text-align: center;
        font-style: normal;
        border-bottom: 1px solid #FFF2F1;
    }
}
</style>